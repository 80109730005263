import React from "react";
import { graphql, Link } from "gatsby";
import "../scss/news-article-page.scss";
import 'uikit/dist/css/uikit.css'

import Layout from "../components/layout";
import SEO from "../components/seo";
import ReactMarkdown from "react-markdown";
import Img from "gatsby-image";
import GetImgUrl from "../utils/customFunctions";

export const query = graphql`
  query newsArticlePagesQuery($id: Int) {
    strapiNewsArticle(strapiId: { eq: $id }) {
      Components
      Title
      id
      slug
      Header {
        RightBgColor
        LeftBgColor
        Title
        Subtitle
        Visible
        CTA
        URL
        CTASecondary
        URLSecondary
        Background {
          localFile {
            publicURL
            childImageSharp {
              fluid(quality: 90, maxWidth: 1900) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;

const newsArticle = ({ data }) => {
  var headerData = {
    headerTitle: data.strapiNewsArticle.Header.Title
      ? data.strapiNewsArticle.Header.Title
      : "",
    headerDescription: data.strapiNewsArticle.Header.Subtitle
      ? data.strapiNewsArticle.Header.Subtitle
      : "",
    headerCTAPrimaryLabel: data.strapiNewsArticle.Header.CTA
      ? data.strapiNewsArticle.Header.CTA
      : "",
    headerCTAPrimaryUrl: data.strapiNewsArticle.Header.URL
      ? data.strapiNewsArticle.Header.URL
      : "",
    headerCTASecondaryLabel: data.strapiNewsArticle.Header.CTASecondary
      ? data.strapiNewsArticle.Header.CTASecondary
      : "",
    headerCTASecondaryUrl: data.strapiNewsArticle.Header.URLSecondary
      ? data.strapiNewsArticle.Header.URLSecondary
      : "",
    headerBgImage: data.strapiNewsArticle.Header.Background?.localFile.childImageSharp.fluid||{src:process.env.GATSBY_DEFAULT_NA_IMAGE},
    headerRightBgColor: data.strapiNewsArticle.Header.RightBgColor ? data.strapiNewsArticle.Header.RightBgColor : '',
    headerLeftBgColor: data.strapiNewsArticle.Header.LeftBgColor ? data.strapiNewsArticle.Header.LeftBgColor : '',

  };

  return (
    <div>
      <Layout
        pageData={headerData}
        headerShow={data.strapiNewsArticle.Header.Visible}
        shareOnSocial={true}
      >
        <SEO title={data.strapiNewsArticle.Title} />
        <div className="news-article-page padding-top padding-bottom lateral-padding">
          {data.strapiNewsArticle.Components.map((item, index) => {
            return (
              <div key={index}>
                <ReactMarkdown children={item.Text} />
                
                {item.ImageCarousel ? (
                  <div className="event-page-image uk-height-medium uk-cover-container uk-margin-none">
                    <Img
                      fluid={
                        GetImgUrl(item.ImageCarousel.Images.localFile___NODE)
                          .fluid
                      }
                      alt="image"
                    ></Img>
                  </div>
                ) : null}

                {item.Links ? (
                  <Link to={item.Links.URL} className="event-page-link">
                    {item.Links.Link}
                  </Link>
                ) : null}
              </div>
            );
          })}
        </div>
      </Layout>
    </div>
  );
};

export default newsArticle;
